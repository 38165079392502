import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Switch, Route, Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Header from '../components/Header/Header.jsx';
import Sidebar from '../components/Sidebar/Sidebar.jsx';

import Typography from '@material-ui/core/Typography';

import appStyle from '../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

import SLogo from '../../assets/images/logo_hq.jpg';
import CLogo from '../../assets/images/logo_c_hq.png';

class Dashboard extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      'mobileOpen': false,
      'miniActive': false,
    };

    this.resizeFunction = this.resizeFunction.bind(this);

  }

  componentDidMount() {

    window.addEventListener('resize', this.resizeFunction);

  }

  componentWillUnmount() {

    window.removeEventListener('resize', this.resizeFunction);

  }

  componentDidUpdate(e) {

    if (e.history.location.pathname !== e.location.pathname) {

      this.refs.mainPanel.scrollTop = 0;

    }

  }

  handleDrawerToggle() {

    this.setState({ 'mobileOpen': !this.state.mobileOpen });

  }

  sidebarMinimize() {

    this.setState({ 'miniActive': !this.state.miniActive });

  }

  resizeFunction() {

    if (window.innerWidth >= 960) {

      this.setState({ 'mobileOpen': false });

    }

  }

  getRoutes(route, key) {

    if (route.redirect) {

      return <Redirect from={route.pathFrom} to={route.path} key={key} />;

    }

    if (route.collapse) {

      // return route.views.filter((view) => !view.hidden).map((viewProp, viewKey) => this.getRoutes(viewProp, viewKey));

      return route.views.map((viewProp, viewKey) => this.getRoutes(viewProp, viewKey));

    }

    return <Route path={route.path} component={route.component} key={key} />;

  }

  render() {

    const { classes, ...rest } = this.props;
    const mainPanel
      = classes.mainPanel
      + ' '
      + cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.props.routesWithProps}
          logoText={'TargetTalk'}
          logo={this.props.isCharlotte ? CLogo : SLogo}
          handleDrawerToggle={this.handleDrawerToggle.bind(this)}
          open={this.state.mobileOpen}
          color={this.props.isCharlotte ? 'green' : 'red'}
          bgColor="white"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={this.props.routesWithProps}
            handleDrawerToggle={this.handleDrawerToggle.bind(this)}
            open={this.state.mobileOpen}
            {...rest}
          />
          <div className={classes.fullWidthContent}>
            <div className={classes.fullWidthContainer}>
              <Switch>
                {this.props.routesWithProps

                  // .filter((route) => !route.fullPage && !route.hidden)
                  .map((route, key) => this.getRoutes(route, key))}
              </Switch>
            </div>
            <div className="push" style={{ 'height': '53px' }} />
          </div>
          <div className="footer" style={{ 'zIndex': '1' }}>
            <Typography
              paragraph
              variant="caption"
              align="center"
              noWrap={false}
              style={{ 'padding': '0 8px', 'marginBottom': 0 }}
            >
              Copyright © {new Date().getFullYear()}{' '}
              {this.props.isCharlotte ? 'Charlotte Products Limited' : 'Swish Maintenance Limited'}. All Rights
              Reserved.
            </Typography>
          </div>
        </div>
      </div>
    );

  }

}

Dashboard.propTypes = {
  'classes': PropTypes.object.isRequired,
  'routesWithProps': PropTypes.array.isRequired,
};

export default withStyles(appStyle)(Dashboard);
