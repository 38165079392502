/* eslint-disable no-undefined */

import { GET_USERS_START, GET_USERS_SUCCESS, GET_USERS_FAILURE } from '../actions/users';

const initialState = {
  'users': [],
  'error': undefined,
};

export default function users(state = initialState, action) {

  switch (action.type) {

    case GET_USERS_START:
      return {
        ...state,
        'users': [],
        'error': undefined,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        'users': action.users,
        'error': undefined,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        'users': [],
        'error': action.error,
      };
    default:
      return state;

  }

}
