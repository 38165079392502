import moment from 'moment';


// As per https://github.com/zaygraveyard/moment-easter/blob/9b118aa73ce85fa10758f1e38938ac56924c5c73/moment.easter.js
const getEaster = () => {

  const year = moment().get('year');

  let a = (year / 100 | 0) * 1483 - (year / 400 | 0) * 2225 + 2613;
  let b = ((year % 19 * 3510 + (a / 25 | 0) * 319) / 330 | 0) % 29;
  let c = 148 - b - ((year * 5 / 4 | 0) + a - b) % 7;

  return moment({ year, 'month': (c / 31 | 0) - 1, 'day': c % 31 + 1 });

};

global.getHolidayDays = (province = 60) => {

  const easter = getEaster();

  const august = moment().set('month', 7).set('date', 1);
  const september = moment().set('month', 8).set('date', 1);

  const october = moment().set('month', 9).set('date', 1).add(1, 'week');

  const nationalDays = {
    'newYearsDay': moment().startOf('year').format('YYYY-MM-DD'),
    'goodFriday': easter.subtract(2, 'days').format('YYYY-MM-DD'),
    'victoriaDay': moment().set('month', 4).set('date', 25).isoWeekday(1).format('YYYY-MM-DD'),
    'canadaDay': moment().set('month', 6).set('date', 1).format('YYYY-MM-DD'),
    'labourDay': (september.isoWeekday() === 1 ? september : september.isoWeekday() === 7 ? september.add(1, 'day') : september.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD'),
    'thanksgivingDay': (october.isoWeekday() === 1 ? october : october.isoWeekday() === 7 ? october.add(1, 'day') : october.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD'),
    'remembranceDay': moment().set('month', 10).set('date', 11).format('YYYY-MM-DD'),
    'christmasDay': moment().set('month', 11).set('date', 25).format('YYYY-MM-DD'),
  };

  const provincialDays = [];

  if (province === 52) { // AB

    provincialDays.push(moment().set('month', 1).startOf('month').add(3, 'weeks').isoWeekday(1).format('YYYY-MM-DD')); // Family Day
    provincialDays.push((august.isoWeekday() === 1 ? august : august.isoWeekday() === 7 ? august.add(1, 'day') : august.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD')); // Civic Day

  } else if (province === 53) { // BC

    provincialDays.push(moment().set('month', 1).startOf('month').add(2, 'weeks').isoWeekday(1).format('YYYY-MM-DD')); // Family Day
    provincialDays.push((august.isoWeekday() === 1 ? august : august.isoWeekday() === 7 ? august.add(1, 'day') : august.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD')); // Civic Day

  } else if (province === 54) { // MB

    provincialDays.push(moment().set('month', 1).startOf('month').add(3, 'weeks').isoWeekday(1).format('YYYY-MM-DD')); // Louis Riel Day

  } else if (province === 55) { // NB

    delete nationalDays.victoriaDay;
    delete nationalDays.thanksgivingDay;

    provincialDays.push(moment().set('month', 1).startOf('month').add(3, 'weeks').isoWeekday(1).format('YYYY-MM-DD')); // Family Day
    provincialDays.push((august.isoWeekday() === 1 ? august : august.isoWeekday() === 7 ? august.add(1, 'day') : august.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD')); // Civic Day

  } else if (province === 56) { // NL

    delete nationalDays.victoriaDay;
    delete nationalDays.thanksgivingDay;
    delete nationalDays.remembranceDay;

    provincialDays.push(moment().set('month', 2).set('date', 17).format('YYYY-MM-DD')); // St. Patrick's Day
    provincialDays.push(moment().set('month', 3).set('date', 23).format('YYYY-MM-DD')); // St. George's Day
    provincialDays.push(moment().set('month', 4).set('date', 24).format('YYYY-MM-DD')); // Discovery Day

  } else if (province === 57) { // NS

    delete nationalDays.victoriaDay;
    delete nationalDays.thanksgivingDay;
    delete nationalDays.remembranceDay;

  } else if (province === 58) { // NU

    provincialDays.push((august.isoWeekday() === 1 ? august : august.isoWeekday() === 7 ? august.add(1, 'day') : august.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD')); // Civic Day
    provincialDays.push(moment().set('month', 5).set('date', 9).format('YYYY-MM-DD')); // Nunavut Day

  } else if (province === 59) { // NT

    provincialDays.push(moment().set('month', 4).set('date', 21).format('YYYY-MM-DD')); // National Aboriginal Day

  } else if (province === 60) { // ON

    delete nationalDays.remembranceDay;

    provincialDays.push(moment().set('month', 1).startOf('month').add(3, 'weeks').isoWeekday(1).format('YYYY-MM-DD')); // Family Day
    provincialDays.push((august.isoWeekday() === 1 ? august : august.isoWeekday() === 7 ? august.add(1, 'day') : august.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD')); // Civic Day
    provincialDays.push(moment().set('month', 11).set('date', 26).format('YYYY-MM-DD'));

  } else if (province === 61) { // PE

    delete nationalDays.victoriaDay;
    delete nationalDays.thanksgivingDay;

    provincialDays.push(moment().set('month', 1).startOf('month').add(3, 'weeks').isoWeekday(1).format('YYYY-MM-DD')); // Islander Day

  } else if (province === 62) { // QC

    delete nationalDays.goodFriday;
    delete nationalDays.remembranceDay;

    provincialDays.push(easter.add(1, 'day').format('YYYY-MM-DD')); // Easter Monday
    provincialDays.push(moment().set('month', 4).set('date', 24).format('YYYY-MM-DD')); // St. Jean Baptiste Day

  } else if (province === 63) { // SK

    provincialDays.push(moment().set('month', 1).startOf('month').add(3, 'weeks').isoWeekday(1).format('YYYY-MM-DD')); // Family Day
    provincialDays.push((august.isoWeekday() === 1 ? august : august.isoWeekday() === 7 ? august.add(1, 'day') : august.add(1, 'week').isoWeekday(1)).format('YYYY-MM-DD')); // Civic Day

  }

  return Object.values(nationalDays).concat(provincialDays).sort();

};

global.isTrendingDown = (currentValue, previousValue, period) => {

  let returnValue = false;

  if (period === 'day') {

    returnValue = currentValue - previousValue;

  } else if (period === 'week') {

    const startOfYear = moment().month() < 9 ? moment().subtract(1, 'year').month(9).startOf('month') : moment().month(9).startOf('month');
    const now = moment();

    let startOfWeek = moment().startOf('week');
    startOfWeek = startOfWeek.diff(startOfYear, 'day') > 0 ? startOfWeek : startOfYear.clone();

    returnValue = currentValue / now.diff(startOfWeek, 'day') < previousValue / 7;

  } else if (period === 'month') {

    const startOfPreviousMonth = moment().subtract(1, 'year').startOf('month');
    const now = moment();

    returnValue = currentValue / now.date() < previousValue / startOfPreviousMonth.daysInMonth();

  } else if (period === 'year') {

    const startOfYear = moment().month() < 9 ? moment().subtract(1, 'year').month(9).startOf('month') : moment().month(9).startOf('month');
    const now = moment();

    returnValue = currentValue / now.diff(startOfYear, 'day') < previousValue / 365;

  }

  return returnValue;

};
