import React from 'react';
import PropTypes from 'prop-types';

// javascript plugin used to create scrollbars on windows
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import sidebarStyle from '../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';

import DefaultAvatar from '../../../assets/images/default-avatar.jpg';

const renderView = (self, classes, color, prop, key) => {

  if (prop.redirect) {

    return null;

  }

  if (prop.collapse) {

    let shouldSplit = prop.name && prop.name.includes('/');

    return (
      <ListItem key={key} className={classes.item}>
        <div
          className={
            classes.itemLink
            + ' '
            + cx({ [' ' + classes.collapseActive]: self.state[prop.state] })
          }
          onClick={() => self.openCollapse(prop.state)}
          style={{ 'cursor': 'pointer' }}
        >
          <ListItemIcon className={classes.itemIcon}>
            {typeof prop.icon === 'string'
              ? <Icon>{prop.icon}</Icon>
              : <prop.icon />
            }
          </ListItemIcon>
          <ListItemText

            // primary={prop.name}
            primary={
              <>
                <Typography variant="body2" display={shouldSplit ? 'block' : 'inline'}>
                  {shouldSplit ? prop.name.split('/')[0] + ' /' : prop.name}
                </Typography>
                {shouldSplit
                  ? <Typography variant="caption" display="inline">{prop.name.split('/')[1]} </Typography>
                  : null}
              </>
            }

            // secondary={prop.name && prop.name.includes('/') ? prop.name.split('/')[1] : null}
            secondary={
              <b
                className={
                  classes.caret
                  + ' '
                  + (self.state[prop.state] ? classes.caretActive : '')
                }
              />
            }
            disableTypography={true}
            className={
              classes.itemText
              + ' '
              + cx({
                [classes.itemTextMini]:
                  self.props.miniActive && self.state.miniActive,
              })
            }
          />
        </div>
        <Collapse in={self.state[prop.state]} unmountOnExit>
          <List className={classes.list + ' ' + classes.collapseList}>
            {prop.views.filter((view) => !view.hidden).map((viewProp, viewKey) =>
              renderView(self, classes, color, viewProp, viewKey)
            )}
          </List>
        </Collapse>
      </ListItem>
    );

  }

  return (
    <ListItem key={key} className={classes.collapseItem}>
      {prop.url

        ? <a
          href={prop.url}
          className={
            classes.collapseItemLink
            + ' '
            + cx({ [' ' + classes[color]]: self.activeRoute(prop.path) })
          }

        >
          <span className={classes.collapseItemMini}>
            {prop.icon
              ? typeof prop.icon === 'string'
                ? <Icon >{prop.icon}</Icon>
                : <prop.icon style={{ 'marginTop': '-4px' }} />
              : prop.mini
            }
          </span>

          <ListItemText
            primary={

              <Typography variant="body2" display="block">
                {prop.name}
              </Typography>

            }
            disableTypography={true}
            className={
              classes.collapseItemText
              + ' '
              + cx({
                [classes.collapseItemTextMini]:
                  self.props.miniActive && self.state.miniActive,
              })
            }
          />
        </a>

        : <NavLink
          to={prop.path}
          className={
            classes.collapseItemLink
            + ' '
            + cx({ [' ' + classes[color]]: self.activeRoute(prop.path) })
          }
          replace={Boolean(prop.url)}
        >
          <span className={classes.collapseItemMini}>
            {prop.icon
              ? typeof prop.icon === 'string'
                ? <Icon >{prop.icon}</Icon>
                : <prop.icon style={{ 'marginTop': '-4px' }} />
              : prop.mini
            }
          </span>

          <ListItemText
            primary={

              <Typography variant="body2" display="block">
                {prop.name}
              </Typography>

            }
            disableTypography={true}
            className={
              classes.collapseItemText
              + ' '
              + cx({
                [classes.collapseItemTextMini]:
                  self.props.miniActive && self.state.miniActive,
              })
            }
          />
        </NavLink>
      }

    </ListItem>
  );

};

class SidebarWrapper extends React.Component {

  render() {

    const { className, user, headerLinks, links, footerLinks } = this.props;

    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
        {footerLinks}
      </div>
    );

  }

}

class Sidebar extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      'miniActive': true,
      'tt': true,
      'ttr': false,
      'ttrq': false,
      'profileOpen': false,
    };

    this.activeRoute.bind(this);

  }

  activeRoute(routeName) {

    return this.props.history.location.pathname.indexOf(routeName) > -1;

  }

  openCollapse(collapse) {

    let st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);

  }

  render() {

    const { classes, color, logo, routes, user, bgColor } = this.props;

    const itemText
      = classes.itemText
      + ' '
      + cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      });
    const userWrapperClass
      = classes.user + ' ' + cx({ [classes.whiteAfter]: bgColor === 'white' });

    const photo = classes.photo;

    let manager = '';
    if (user.Manager && user.Manager.givenName && user.Manager.surname) {

      manager = `${user.Manager.givenName} ${user.Manager.surname}`;

    }

    const userLinks
      = Object.keys(user).length !== 0
        ? <div className={userWrapperClass}>
          <div className={photo}>
            <img
              src={user.photo || DefaultAvatar}
              className={classes.avatarImg}
              alt={user.givenName + (user.surname ? ' ' + user.surname : '')}
            />
          </div>

          <List className={classes.list}>
            <ListItem
              className={classes.item + ' ' + classes.userItem}
              style={{ 'cursor': 'pointer' }}
              onClick={() => this.setState((prev) => ({ ...prev, 'profileOpen': !prev.profileOpen }))}
            >
              <ListItemText
                primary={
                  <Typography >
                    {user.givenName + (user.surname ? ' ' + user.surname : '')}
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" gutterBottom component={'span'}>
                    {manager && `Manager: ${manager}`}
                    <b
                      className={
                        classes.caret
                        + ' '
                        + (this.state.profileOpen ? classes.caretActive : '')
                      }
                    />
                  </Typography>
                }
                className={
                  classes.itemLink + ' ' + itemText + ' ' + classes.userItemText
                }
              />
            </ListItem>

            {this.state.profileOpen
              ? <Collapse in={this.state.profileOpen} unmountOnExit>
                <ListItem className={classes.item}>

                  <NavLink
                    to={'/tools/profile'}
                    className={
                      classes.itemLink

                    }
                  >
                    <ListItemText
                      primary={'Profile'}
                      disableTypography={true}
                      className={classes.itemText}
                    />
                  </NavLink>

                </ListItem></Collapse>
              : null}
          </List>

        </div >

        : <div />
      ;
    const links
      = Object.keys(user).length !== 0
        ? <List className={classes.list}>
          {routes
            .filter(
              (route) =>
                !route.hidden
                && (!('footer' in route) || route.footer === false)
            )
            .map((prop, key) => renderView(this, classes, color, prop, key))}
        </List>

        : <div />
      ;
    const footerLinks
      = <List className={classes.footerList}>
        {routes
          .filter((route) => !route.hidden && route.footer === true)
          .map((prop, key) => {

            if (prop.url) {

              return (
                <ListItem key={key} className={classes.item}>
                  <a
                    href={prop.url}
                    className={
                      classes.itemLink
                      + ' '
                      + cx({
                        [' ' + classes[color]]: this.activeRoute(prop.path),
                      })
                    }
                  >
                    <ListItemText
                      primary={prop.name}
                      disableTypography={true}
                      className={
                        classes.itemText
                        + ' '
                        + cx({
                          [classes.itemTextMini]:
                            this.props.miniActive && this.state.miniActive,
                        })
                      }
                    />
                  </a>
                </ListItem>
              );

            } else if ('authenticated' in prop) {

              if (prop.authenticated) {

                if (Object.keys(user).length !== 0) {

                  return (
                    <ListItem key={key} className={classes.item}>
                      <NavLink
                        to={prop.path}
                        className={
                          classes.itemLink
                          + ' '
                          + cx({
                            [' ' + classes[color]]: this.activeRoute(prop.path),
                          })
                        }
                      >
                        <ListItemText
                          primary={prop.name}
                          disableTypography={true}
                          className={
                            classes.itemText
                            + ' '
                            + cx({
                              [classes.itemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                            })
                          }
                        />
                      </NavLink>
                    </ListItem>
                  );

                }

              } else if (Object.keys(user).length === 0) {

                return (
                  <ListItem key={key} className={classes.item}>
                    <NavLink
                      to={prop.path}
                      className={
                        classes.itemLink
                        + ' '
                        + cx({
                          [' ' + classes[color]]: this.activeRoute(prop.path),
                        })
                      }
                    >
                      <ListItemText
                        primary={prop.name}
                        disableTypography={true}
                        className={
                          classes.itemText
                          + ' '
                          + cx({
                            [classes.itemTextMini]:
                              this.props.miniActive && this.state.miniActive,
                          })
                        }
                      />
                    </NavLink>
                  </ListItem>
                );

              }

            } else {

              return (
                <ListItem key={key} className={classes.item}>
                  <NavLink
                    to={prop.path}
                    className={
                      classes.itemLink
                      + ' '
                      + cx({
                        [' ' + classes[color]]: this.activeRoute(prop.path),
                      })
                    }
                  >
                    <ListItemText
                      primary={prop.name}
                      disableTypography={true}
                      className={
                        classes.itemText
                        + ' '
                        + cx({
                          [classes.itemTextMini]:
                            this.props.miniActive && this.state.miniActive,
                        })
                      }
                    />
                  </NavLink>
                </ListItem>
              );

            }

          })}
      </List>
      ;

    const brand
      = <a href="/" className={classes.logo}>
        <img src={logo} alt="logo" className={classes.img} />
      </a>
      ;

    const drawerPaper
      = classes.drawerPaper
      + ' '
      + cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      });

    const sidebarWrapper
      = classes.sidebarWrapper
      + ' '
      + cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      });

    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            anchor={'right'}
            variant="temporary"
            open={this.props.open}
            classes={{
              'paper': drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={this.props.handleDrawerToggle}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={userLinks}
              links={links}
              footerLinks={footerLinks}
            />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ 'miniActive': false })}
            onMouseOut={() => this.setState({ 'miniActive': true })}
            anchor={'left'}
            variant="permanent"
            open
            classes={{
              'paper': drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={userLinks}
              links={links}
              footerLinks={footerLinks}
            />
          </Drawer>
        </Hidden>
      </div>
    );

  }

}

Sidebar.defaultProps = {
  'bgColor': 'blue',
};

Sidebar.propTypes = {
  'classes': PropTypes.object.isRequired,
  'bgColor': PropTypes.oneOf(['white', 'black', 'blue']),
  'color': PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose',
  ]),
  'logo': PropTypes.string,
  'logoText': PropTypes.string,
  'routes': PropTypes.arrayOf(PropTypes.object),
  'user': PropTypes.object,
};

export default withStyles(sidebarStyle)(Sidebar);
