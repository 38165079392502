// import axios from 'axios';
// import moment from 'moment';

// Login Section
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const DISMISS_NOTIFICATION_REQUEST = 'DISMISS_NOTIFICATION_REQUEST';
export const DISMISS_NOTIFICATION_SUCCESS = 'DISMISS_NOTIFICATION_SUCCESS';

const requestNotifications = () => ({
  'type': GET_NOTIFICATIONS_REQUEST,
});

const receiveNotifications = (notifications) => ({
  'type': GET_NOTIFICATIONS_SUCCESS,
  notifications,
});

const requestNotificationDismiss = () => ({
  'type': DISMISS_NOTIFICATION_REQUEST,
});

const receiveNotificationDismiss = (notifications) => ({
  'type': DISMISS_NOTIFICATION_SUCCESS,
  notifications,
});

export const getNotifications = () => {

  return (dispatch) => {

    dispatch(requestNotifications());
    dispatch(receiveNotifications([]));

    // axios('/notifications').then((response) => dispatch(receiveNotifications(response.data.results))).catch(() => {});

  };

};

export const dismissNotification = (notification, history) => {

  return (dispatch) => {

    dispatch(requestNotificationDismiss());

    // axios('/notification/dismiss', {
    //   'method': 'post',
    //   'data': { 'notificationId': notification.notificationId },
    // }).then(() => {
    //
    //   let quarter = moment().quarter();
    //   quarter = quarter === 4 ? 1 : quarter + 1;
    //
    //   history.push({
    //     'pathname': '/targettalk/okrs/' + quarter,
    //     'state': {
    //       'priorityId': notification.priorityId,
    //       'objectiveId': notification.objectiveId,
    //       'keyResultId': notification.keyResultId,
    //       'userId': notification.userId,
    //     },
    //   });
    //

    dispatch(receiveNotificationDismiss);
    dispatch(getNotifications);

    //
    // }).catch(() => { });

  };

};
