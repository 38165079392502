import {
  GET_DEPARTMENTS_START,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
} from '../actions/department';

const initialState = {
  'departmentsLoaded': false,
  'departments': [],
  'departmentsErrorMessage': '',
};

export default function reduce(state = initialState, action) {

  switch (action.type) {

    case GET_DEPARTMENTS_START:
      return { ...state, 'departmentsLoaded': false };

    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        'departments': action.payload,
        'departmentsLoaded': true,
        'departmentsErrorMessage': '',
      };

    case GET_DEPARTMENTS_FAILURE:
      return {
        ...state,
        'departments': [],
        'departmentsLoaded': true,
        'departmentsErrorMessage': action.payload,
      };

    default:
      return state;

  }

}
