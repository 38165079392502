import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  DISMISS_NOTIFICATION_SUCCESS,
} from '../actions/general';

const initialState = {
  'notifications': [],
};

export default function authentication(state = initialState, action) {

  switch (action.type) {

    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        'notifications': [],

      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        'notifications': action.notifications,

      };

    case DISMISS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        'notifications': action.notifications,
      };

    default:
      return state;

  }

}
