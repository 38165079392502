import axios from 'axios';

// Login Section
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false
  };
};

const receiveLogin = (user, token, expiry, message) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user,
    token,
    expiry,
    message
  };
};

const loginError = error => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    error
  };
};

export const loginUser = (firebase, history) => {
  return dispatch => {
    dispatch(requestLogin());

    const getFirebaseLogin = () =>
      new Promise((resolve, reject) => {
        firebase
          .login({ provider: 'google', type: 'popup' })
          .then(loginResult => {
            return resolve(loginResult.user);
          })
          .catch(err => {
            return reject(err.message);
          });
      });

    const createOrUpdateUser = user =>
      new Promise((resolve, reject) => {
        axios.defaults.headers.common['x-api-key'] = global.API_KEY;

        const names = user.displayName.split(' ');

        axios('/auth/user', {
          method: 'post',
          data: {
            firebaseId: user.uid,
            givenName: names[0] || '',
            surname: names.length > 1 ? names[1] : '',
            email: user.email,
            photo: user.photoURL
          }
        })
          .then(token => {
            token = token.data.result;

            axios.defaults.headers.common.authorization = token;

            return resolve(token);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    const getUserDetails = token =>
      new Promise((resolve, reject) => {
        axios('/auth/user', {
          method: 'get'
        })
          .then(response => {
            return resolve([token, response.data.result]);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    const getTokenVerification = ([token, user]) =>
      new Promise((resolve, reject) => {
        const location = window.location;

        axios('/token/verify', {
          baseURL: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
          method: 'get'
        })
          .then(decoded => {
            return resolve([token, user, decoded.data.exp]);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    getFirebaseLogin()
      .then(createOrUpdateUser)
      .then(getUserDetails)
      .then(getTokenVerification)
      .then(([token, user, expiry]) => {
        if (history.length > 2) {
          history.goBack();
        } else {
          history.push('/home');
        }

        return dispatch(receiveLogin(user, token, expiry, 'Successful Login!'));
      })
      .catch(err => {
        return dispatch(loginError(err));
      });
  };
};

export const loginUserByJwt = (token, history, page = null) => {
  return dispatch => {
    dispatch(requestLogin());

    axios.defaults.headers.common.authorization = token;

    const getUserDetails = () =>
      new Promise((resolve, reject) => {
        axios('/auth/user', {
          method: 'get'
        })
          .then(response => {
            return resolve(response.data.result);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    const getTokenVerification = user =>
      new Promise((resolve, reject) => {
        const location = window.location;

        axios('/token/verify', {
          baseURL: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
          method: 'get'
        })
          .then(decoded => {
            return resolve([user, decoded.data.exp]);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    getUserDetails()
      .then(getTokenVerification)
      .then(([user, expiry]) => {
        history.push(page ? page : '/sales-programs/kpi-customers');

        return dispatch(receiveLogin(user, token, expiry, 'Successful Login!'));
      })
      .catch(err => {
        return dispatch(loginError(err));
      });
  };
};

export const loginUserByUid = uid => {
  return dispatch => {
    dispatch(requestLogin());

    const createOrUpdateUser = () =>
      new Promise((resolve, reject) => {
        axios.defaults.headers.common['x-api-key'] = global.API_KEY;

        axios('/83ca85fc', {
          method: 'post',
          data: { uid }
        })
          .then(token => {
            token = token.data.result;

            axios.defaults.headers.common.authorization = token;

            return resolve(token);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    const getUserDetails = token =>
      new Promise((resolve, reject) => {
        axios('/auth/user', {
          method: 'get'
        })
          .then(response => {
            return resolve([token, response.data.result]);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    const getTokenVerification = ([token, user]) =>
      new Promise((resolve, reject) => {
        const location = window.location;

        axios('/token/verify', {
          baseURL: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
          method: 'get'
        })
          .then(decoded => {
            return resolve([token, user, decoded.data.exp]);
          })
          .catch(err => {
            return reject(err.response.data.message);
          });
      });

    createOrUpdateUser()
      .then(getUserDetails)
      .then(getTokenVerification)
      .then(([token, user, expiry]) => {
        // history.push('/targettalk/home');

        return dispatch(receiveLogin(user, token, expiry, 'Successful Login!'));
      })
      .catch(err => {
        return dispatch(loginError(err));
      });
  };
};

// Logout Section
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
};

// Logs the user out
export const logoutUser = () => {
  return dispatch => {
    delete axios.defaults.headers.common.authorization;
    dispatch(receiveLogout());
  };
};

// Token Refresh Section
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

const requestRefreshToken = () => {
  return {
    type: REFRESH_TOKEN_REQUEST,
    isFetching: true
  };
};

const receiveRefreshToken = (token, expiry) => {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token,
    expiry
  };
};

const tokenRefreshError = error => {
  return {
    type: REFRESH_TOKEN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    error
  };
};

// Calls the API to refresh a token and
// dispatches actions along the way
export const refreshToken = () => {
  return dispatch => {
    dispatch(requestRefreshToken());

    const getRefreshToken = () =>
      new Promise((resolve, reject) => {
        axios('/auth/token/refresh', {
          method: 'get'
        })
          .then(token => {
            token = token.data.result;

            axios.defaults.headers.common.authorization = token;

            return resolve(token);
          })
          .catch(err => {
            return reject(err);
          });
      });

    const getTokenVerification = token =>
      new Promise((resolve, reject) => {
        axios('/token/verify', {
          baseURL: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
          method: 'get'
        })
          .then(decoded => {
            return resolve([token, decoded.data.exp]);
          })
          .catch(err => {
            return reject(err);
          });
      });

    return getRefreshToken()
      .then(getTokenVerification)
      .then(([token, expiry]) => {
        return dispatch(receiveRefreshToken(token, expiry));
      })
      .catch(err => {
        const error = new Error(err.response.data.message);
        error.name = err.response.data.code;

        return dispatch(tokenRefreshError(error));
      });
  };
};
