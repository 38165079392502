// ##############################
// // // App styles
// #############################

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid,
} from '../../../jss/material-dashboard-pro-react.jsx';

const appStyle = (theme) => ({
  'wrapper': {
    'position': 'relative',
    'top': '0',
    'height': '100vh',
    '&:after': {
      'display': 'table',
      'clear': 'both',
      'content': '" "',
    },
  },
  'mainPanel': {
    'height': '100%',
    'minHeight': '100%',
    'transitionProperty': 'top, bottom, width',
    'transitionDuration': '.2s, .2s, .35s',
    'transitionTimingFunction': 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      'width': `calc(100% - ${drawerWidth}px)`,
    },
    'overflow': 'auto',
    'position': 'relative',
    'float': 'right',
    ...transition,
    'maxHeight': '100%',
    'width': '100%',
    'overflowScrolling': 'touch',
  },
  'content': {
    'marginTop': '70px',
    'marginBottom': '24px',
    'padding': '16px',
  },
  'fullWidthContent': {
    'minHeight': '100%',
    'margin': '0 auto -53px',
    'padding': '16px 0 0 0',
  },
  'container': { ...containerFluid },
  'fullWidthContainer': {
    ...containerFluid,
    'marginTop': '52px',
    'paddingRight': '0',
    'paddingLeft': '0',
  },
  'map': {
    'marginTop': '70px',
  },
  'mainPanelSidebarMini': {
    [theme.breakpoints.up('md')]: {
      'width': `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  'mainPanelWithPerfectScrollbar': {
    'overflowX': 'hidden !important',
  },
});

export default appStyle;
