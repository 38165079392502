import {
  GET_COMPANIES_START,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
} from '../actions/company';

const initialState = {
  'companiesLoaded': false,
  'companies': [],
  'companiesErrorMessage': '',
};

export default function reduce(state = initialState, action) {

  switch (action.type) {

    case GET_COMPANIES_START:
      return { ...state, 'companiesLoaded': false };

    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        'companies': action.payload,
        'companiesLoaded': true,
        'companiesErrorMessage': '',
      };

    case GET_COMPANIES_FAILURE:
      return {
        ...state,
        'companies': [],
        'companiesLoaded': true,
        'companiesErrorMessage': action.payload,
      };

    default:
      return state;

  }

}
