import axios from 'axios';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const getUsersStart = () => {

  return {
    'type': GET_USERS_START,
  };

};

export const getUsersSuccess = (users) => {

  return {
    'type': GET_USERS_SUCCESS,
    users,
  };

};

export const getUsersFailure = (error) => {

  return {
    'type': GET_USERS_FAILURE,
    error,
  };

};

export const getUsers = () => {

  return (dispatch) => {

    dispatch(getUsersStart());

    axios('/users')
      .then((response) => dispatch(getUsersSuccess(response.data.results)))
      .catch((err) => dispatch(getUsersFailure(err.response.data)));

  };

};
