/* eslint-disable no-undefined */
import { useEffect, useState } from 'react';

import Promise from 'bluebird';
import axios from 'axios';
import moment from 'moment';

const useRedirectToTimeSheet = () => {

  const CancelToken = axios.CancelToken;
  const token = CancelToken.source();

  const [ cancelToken, setCancelToken ] = useState(token);

  useEffect(() => {

    const cleanUp = (t) => {

      if (t) {

        t.cancel('Component Unmounted');

      }

    };

    setCancelToken(token);

    return () => {

      cleanUp(token);

    };

  }, []);

  const getHolidays = () => {

    const holidays = global.getHolidayDays(60);
    return Promise.resolve(holidays);

  };

  const getVacation = (user) =>
    axios(`vacations/${user.uid}`, {
      'method': 'GET',
      'cancelToken': cancelToken.token,
    }).then((resp) => resp.data.results);

  const getHours = (user, date) =>
    axios(`hours/date/${user.uid}/${date.format('YYYYMMDD')}`, {
      'method': 'GET',
      'cancelToken': cancelToken.token,
    }).then((resp) => resp.data.result);

  const getPreviousWorkingDay = () =>
    getHolidays().then((holidays) => {

      const isHoliday = (time) => {

        const targetTimeZone = time.tz();
        return holidays.some(
          (h) =>

            moment.tz(h, targetTimeZone).startOf('day') <= time && moment.tz(h, targetTimeZone).endOf('day') >= time
        );

      };

      let calcLast = moment().clone();
      let dayOfWeek = calcLast.day();
      do {

        calcLast.subtract(1, 'day');
        dayOfWeek = calcLast.day();

      } while (dayOfWeek === 0 || dayOfWeek === 6 || isHoliday(calcLast));

      return Promise.resolve(calcLast);

    });

  const shouldRedirect = (user) => {

    return getPreviousWorkingDay().then((date) => {

      return Promise.all([ getHours(user, date), getVacation(user) ]).spread((hours, vacation) => {

        const decision = hours < 6 && !vacation.some((s) => date.isBetween(s.startDate, s.endDate, 'day', '[]'));
        return decision;

      });

    });

  };

  return { shouldRedirect };

};

export default useRedirectToTimeSheet;
