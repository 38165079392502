import axios from 'axios';

export const GET_BRANCHES_START = 'GET_BRANCHES_START';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILURE = 'GET_BRANCHES_FAILURE';

export const getBranchesStart = () => {

  return {
    'type': GET_BRANCHES_START,
  };

};

export const getBranchesSuccess = (branches) => {

  return {
    'type': GET_BRANCHES_SUCCESS,
    'payload': branches,
  };

};

export const getBranchesFailure = (err) => {

  return {
    'type': GET_BRANCHES_FAILURE,
    'payload': err.message,
  };

};

export const getBranches = () => {

  return (dispatch) => {

    dispatch(getBranchesStart());
    axios('facilities?order=name')
      .then((resp) => dispatch(getBranchesSuccess(resp.data.results)))
      .catch((err) => dispatch(getBranchesFailure(err)));

  };

};
