import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import classNames from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';

// @material-ui/icons
import Notifications from '@material-ui/icons/Notifications';

// core components
import Button from '../../components/CustomButtons/Button.jsx';

import headerLinksStyle from '../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle';

import { dismissNotification } from '../../../datastore/actions/general';

class HeaderLinks extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      'open': false,
    };

  }

  handleClick() {

    this.setState({ 'open': !this.state.open });

  }

  handleClose() {

    this.setState({ 'open': false });

  }

  render() {

    const { classes } = this.props;
    const { open } = this.state;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });

    return (
      this.props.notifications.length > 0 && (
        <React.Fragment>
          <div>
            <div className={managerClasses}>
              <Button
                disabled={this.props.notifications.length === 0}
                color="transparent"
                justIcon
                aria-label="Notifications"
                aria-owns={open ? 'menu-list' : null}
                aria-haspopup="true"
                onClick={() => this.handleClick()}
                className={classes.buttonLink}
                buttonRef={(node) => {

                  this.anchorEl = node;

                }}
              >
                <Notifications className={classes.headerLinksSvg + ' ' + classes.links} />
                <span className={classes.notifications}>{this.props.notifications.length}</span>
                <Hidden mdUp implementation="css">
                  <span onClick={() => this.handleClick()} className={classes.linkText}>
                    {'Notification'}
                  </span>
                </Hidden>
              </Button>
              <Popper
                open={open}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                  [classes.popperClose]: !open,
                  [classes.pooperResponsive]: true,
                  [classes.pooperNav]: true,
                })}
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps} id="menu-list" style={{ 'transformOrigin': '0 0 0' }}>
                    <Paper className={classes.dropdown}>
                      <ClickAwayListener onClickAway={() => this.handleClose()}>
                        <MenuList role="menu">
                          {this.props.notifications.map((notification, notificationIndex) => (
                            <MenuItem
                              key={notificationIndex}
                              onClick={() =>
                                this.setState({ 'open': false }, () =>
                                  this.props.dispatch(dismissNotification(notification, this.props.history))
                                )
                              }
                              className={dropdownItem}
                            >
                              {notification.notificationText}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </React.Fragment>
      )
    );

  }

}

HeaderLinks.propTypes = {
  'classes': PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ 'notifications': state.general.notifications });

export default withStyles(headerLinksStyle)(connect(mapStateToProps)(HeaderLinks));
