import axios from 'axios';

export const GET_COMPANIES_START = 'GET_COMPANIES_START';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';

export const getCompaniesStart = () => {

  return {
    'type': GET_COMPANIES_START,
  };

};

export const getCompaniesSuccess = (companies) => {

  return {
    'type': GET_COMPANIES_SUCCESS,
    'payload': companies,
  };

};

export const getCompaniesFailure = (err) => {

  return {
    'type': GET_COMPANIES_FAILURE,
    'payload': err.message,
  };

};

export const getCompanies = () => {

  return (dispatch) => {

    dispatch(getCompaniesStart());
    axios('companies?order=name')
      .then((resp) => dispatch(getCompaniesSuccess(resp.data.results)))
      .catch((err) => dispatch(getCompaniesFailure(err)));

  };

};
