import localForage from 'localforage';

import { firebaseReducer } from 'react-redux-firebase';
import { persistCombineReducers } from 'redux-persist';
import { routerReducer } from 'react-router-redux';

import authenticationReducer from './authentication';
import branchesReducer from './branch';
import companiesReducer from './company';
import departmentsReducer from './department';
import generalReducer from './general';
import rehydrateReducer from './rehydrate';
import projectsReducer from './project';
import usersReducer from './users';

const rootReducer = persistCombineReducers(
  {
    'transforms': [

      // createExpirationTransform({
      //   expireKey: "expiry"
      // })
    ],
    'blacklist': [ 'timesheet', 'company', 'project', 'favourite', 'rehydrate' ],

    'key': 'react-redux-boilerplate',
    'storage': localForage,
  },
  {
    'authentication': authenticationReducer,
    'branch': branchesReducer,
    'company': companiesReducer,
    'department': departmentsReducer,
    'firebase': firebaseReducer,
    'general': generalReducer,
    'rehydrate': rehydrateReducer,
    'project': projectsReducer,
    'routing': routerReducer,
    'users': usersReducer,
  }
);

export default rootReducer;
