module.exports = {
  'firebase': {
    'apiKey': 'AIzaSyAFZxG9WriZprk-JNk2V9GcqXH84G4zJuM',
    'authDomain': 'swimaipurchaserequestsfrontend.firebaseapp.com',
    'databaseURL': 'https://swimaipurchaserequestsfrontend.firebaseio.com',
    'projectId': 'swimaipurchaserequestsfrontend',
    'storageBucket': 'swimaipurchaserequestsfrontend.appspot.com',
    'messagingSenderId': '907540765225',
  },
  'googleMap':{
    'apiKey':'AIzaSyA6GIz8JmtTgP8JoFUZuzWO-s2xe5JmzMg'
  }
};
