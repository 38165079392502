import React from 'react';
import PropTypes from 'prop-types';

import { Switch, Route } from 'react-router-dom';

import Dashboard from '../../theme/layouts/Dashboard';


const getRoutes = (routesWithProps, rest) => {

  let routes = <Dashboard routesWithProps={routesWithProps} {...rest} />;

  const checkRoute = (route) => {

    if (route.path === rest.location.pathname && route.fullPage) {

      routes = (
        <Switch>
          <Route path={route.path} component={route.component} />
        </Switch>
      );

      return true;

    }

    return false;

  };

  routesWithProps.some((route) => {

    if (route.views && route.views.length !== 0) {

      return route.views.some((view) => checkRoute(view));

    }

    return checkRoute(route);

  });

  return routes;

};

const Base = ({ routesWithProps, ...rest }) => (
  <div className="root">
    {getRoutes(routesWithProps, rest)}
  </div>
);

Base.propTypes = {
  'routesWithProps': PropTypes.array.isRequired,
};

export default Base;
