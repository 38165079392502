import {
  GET_BRANCHES_START,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAILURE,
} from '../actions/branch';

const initialState = {
  'branchesLoaded': false,
  'branches': [],
  'branchesErrorMessage': '',
};

export default function reduce(state = initialState, action) {

  switch (action.type) {

    case GET_BRANCHES_START:
      return { ...state, 'branchesLoaded': false };

    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        'branches': action.payload,
        'branchesLoaded': true,
        'branchesErrorMessage': '',
      };

    case GET_BRANCHES_FAILURE:
      return {
        ...state,
        'branches': [],
        'branchesLoaded': true,
        'branchesErrorMessage': action.payload,
      };

    default:
      return state;

  }

}
