import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import queryString from 'query-string';
import { omit } from 'lodash';

import Bundle from '../../containers/common/Bundle';
import Base from '../../components/common/Base';
import Loading from '../../components/common/Loading';

import useRedirectToTimeSheet from '../time-sheet/hooks/use-redirect-to-time-sheet';

const mapStateToProps = state => {
  const { authentication, rehydrate, users } = state;

  const { isAuthenticated, user, expiry, message, token, error } = authentication;
  const { isRehydrated } = rehydrate;

  return {
    isAuthenticated,
    user,
    token,
    expiry,
    message,
    error,
    isRehydrated,
    users: users.users
  };
};

const onAddNavigation = (isRehydrated, location, pages, lastPage, setLastPage, cancelToken) => {
  if (isRehydrated) {
    if (location.pathname !== lastPage) {
      const route = pages.find(page => page.path === location.pathname);
      const params = { ...queryString.parse(location.search), ...location.state };

      if (route && !['/home', '/auth/login', '/auth/logout'].includes(route.path)) {
        setLastPage(location.pathname + location.search + location.hash);

        axios('/user-navigation', {
          method: 'POST',
          cancelToken: cancelToken.token,
          data: {
            userNavigationTypeId: 1,
            path: route.path + location.search + location.hash,
            pathName:
              route.name +
              (Object.keys(params).length === 0
                ? ''
                : ' - ' +
                  Object.entries(params).map(
                    ([key, value]) => key.charAt(0).toUpperCase() + key.slice(1) + ': ' + value
                  ))
          }
        })
          .then(() => {})
          .catch(() => {});
      }
    }
  } else {
    setTimeout(() => {
      onAddNavigation(isRehydrated, location, pages, lastPage, setLastPage, cancelToken);
    }, 100);
  }
};

const isHidden = (route, permissions) => {
  if (route.hidden) {
    return true;
  }

  if (route.public) {
    return false;
  }

  return !permissions.includes(route.path);
};

const getPages = (route, permissions, prevName) => {
  const pageViews = [];

  if (route.hidden || isHidden(route, permissions) || route.footer || !route.name) {
    return pageViews;
  }

  if (!route.collapse) {
    pageViews.push({
      name: (prevName ? prevName + ' > ' : '') + route.name,
      path: route.path,
      icon: route.icon
    });
  }

  route.views &&
    route.views.forEach(view => {
      pageViews.push(...getPages(view, permissions, route.name));
    });

  return pageViews;
};

const BasePage = ({ history, routes, ...rest }) => {
  const { isAuthenticated, user, expiry, message, token, error, isRehydrated, users } = useSelector(
    mapStateToProps,
    shallowEqual
  );
  const dispatch = useDispatch();

  const cancelToken = axios.CancelToken.source();

  const [permissions, setPermissions] = useState([]);
  const [pages, setPages] = useState(
    routes
      .map(route => getPages(route, permissions))
      .reduce((allRoutes, currentRoutes) => allRoutes.concat(currentRoutes), [])
  );
  const [lastPage, setLastPage] = useState(null);

  const { shouldRedirect } = useRedirectToTimeSheet();
  const [redirectToTimeSheet, setRedirectToTimeSheet] = useState(null);
  const redirectToTimeSheetWhiteList = []; // 223 = Emma, 568=Rick, 639 = Tim

  useEffect(() => {
    if (history.location.pathname === '/') {
      history.push('/home');
    }

    const removeHistoryListener = history.listen(location => {
      onAddNavigation(isRehydrated, location, pages, lastPage, setLastPage, cancelToken);
    });

    return () => {
      removeHistoryListener();
    };
  }, []);

  useEffect(() => {
    if (isRehydrated && isAuthenticated) {
      axios('/page-permissions', {
        method: 'GET',
        cancelToken: cancelToken.token
      })
        .then(response => {
          setPermissions(response.data.results);
        })
        .catch(() => {});
    }
  }, [isRehydrated, isAuthenticated, user.uid]);

  useEffect(() => {
    setPages(
      routes
        .map(route => getPages(route, permissions))
        .reduce((allRoutes, currentRoutes) => allRoutes.concat(currentRoutes), [])
    );
  }, [
    routes
      .map(route => getPages(route, permissions))
      .reduce((allRoutes, currentRoutes) => allRoutes.concat(currentRoutes), []).length
  ]);

  useEffect(() => {
    onAddNavigation(isRehydrated, location, pages, lastPage, setLastPage, cancelToken);
  }, [history.location.pathname + history.location.search + history.location.hash]);

  useEffect(() => {
    if (
      isRehydrated &&
      isAuthenticated &&
      global.NODE_ENV === 'production' &&
      (redirectToTimeSheet || redirectToTimeSheet === null) &&
      redirectToTimeSheetWhiteList.includes(user.uid) &&
      !['/tools/time-sheet', '/tools/projects', '/auth/login', '/auth/logout'].includes(history.location.pathname)
    ) {
      shouldRedirect(user)
        .then(decision => {
          setRedirectToTimeSheet(decision);
          if (decision) {
            history.replace('/tools/time-sheet');
          }
        })
        .catch(() => {});
    }
  }, [isRehydrated, isAuthenticated, history.location.pathname]);

  // Added exception for lindsey.barron@charlotteproducts
  if (isRehydrated) {
    const isCharlotte =
      (user &&
        (user.email || '').toLowerCase().includes('charlotteproducts') &&
        user.email !== 'lindsey.barron@charlotteproducts.com') ||
      window.location.hostname.toLowerCase().includes('charlotteproducts');

    const globalProps = {
      history,
      params: { ...queryString.parse(history.location.search), ...history.location.state },
      isCharlotte,
      theme: {
        colour: isCharlotte ? '#008556' : '#E31A38',
        lightColour: isCharlotte ? '#66EBBC' : '#FF809E'
      },
      dispatch,
      isAuthenticated,
      user,
      expiry,
      message,
      token,
      error,
      users,
      pages
    };

    const views = route =>
      route.views
        ? route.views.map(view => ({
            ...view,
            hidden: view.hidden || isHidden(view, permissions),
            views: views(view),
            component: () => (
              <Bundle load={view.bundle}>
                {Component => (
                  <Component
                    {...{
                      path: view.path || view.pathFrom,
                      match: matchPath(history.location.pathname + history.location.search, {
                        path: view.path || view.pathFrom,
                        exact: true,
                        strict: false
                      }),
                      ...globalProps
                    }}
                  />
                )}
              </Bundle>
            )
          }))
        : [];

    const routesWithProps = routes.map(route => ({
      ...route,
      hidden:
        route.hidden ||
        isHidden(route, permissions) ||
        (route.hideFromCharlotte && isCharlotte) ||
        (route.hideFromSwish && !isCharlotte),
      views: views(route),
      component: () => (
        <Bundle load={route.bundle}>
          {Component => (
            <Component
              {...{
                path: route.path || route.pathFrom,
                match: matchPath(history.location.pathname + history.location.search, {
                  path: route.path || route.pathFrom,
                  exact: true,
                  strict: false
                }),
                ...globalProps
              }}
            />
          )}
        </Bundle>
      )
    }));

    return (
      <Base
        routesWithProps={routesWithProps}
        isCharlotte={isCharlotte}
        {...omit(
          {
            history,
            ...{ dispatch, isAuthenticated, user, expiry, message, token, error, isRehydrated, users },
            ...rest
          },
          'routes'
        )}
      />
    );
  }

  return <Loading history={history} />;
};

export default BasePage;
