import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

// material-ui icons
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import HeaderLinks from './HeaderLinks';
import Button from '../CustomButtons/Button.jsx';

import headerStyle from '../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx';

const Header = ({ ...props }) => {

  const makeBrand = () => {

    let brandName = '';

    props.routes.forEach((prop) => {

      if (prop.collapse) {

        prop.views.forEach((viewProp) => {

          if (props.history.location.pathname.includes(viewProp.path)) {

            brandName
              = prop.name + ' > ' + (viewProp.nameProp ? viewProp.nameProp(props.state) || viewProp.name : viewProp.name);

          }

        });

      }

      if (brandName === '' && props.history.location.pathname.includes(prop.path)) {

        brandName = prop.name;

      }

    });

    return brandName;

  };

  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize
    = classes.sidebarMinimize
    + ' '
    + cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            <Typography display="block" noWrap={true}>
              {makeBrand()}
            </Typography>

          </Button>
        </div>
        <Hidden mdUp implementation="css">
          <div className={sidebarMinimize}>
            {props.open ? (
              <Button justIcon round color="white" onClick={props.handleDrawerToggle}>
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button justIcon round color="white" onClick={props.handleDrawerToggle}>
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <HeaderLinks history={props.history} />
      </Toolbar>
    </AppBar>
  );

};

Header.propTypes = {
  'classes': PropTypes.object.isRequired,
  'color': PropTypes.oneOf([ 'primary', 'info', 'success', 'warning', 'danger' ]),
  'rtlActive': PropTypes.bool,
};

const mapStateToProps = (state) => ({ state });

export default withStyles(headerStyle)(connect(mapStateToProps)(Header));
