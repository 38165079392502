import axios from 'axios';

export const GET_PROJECTS_START = 'GET_PROJECTS_START';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const getProjectsStart = () => {

  return {
    'type': GET_PROJECTS_START,
  };

};

export const getProjectsSuccess = (projects) => {

  return {
    'type': GET_PROJECTS_SUCCESS,
    'payload': projects,
  };

};

export const getProjectsFailure = (err) => {

  return {
    'type': GET_PROJECTS_FAILURE,
    'payload': err.message,
  };

};

export const getProjects = () => {

  return (dispatch) => {

    dispatch(getProjectsStart());
    axios('projects')
      .then((resp) => dispatch(getProjectsSuccess(resp.data.results)))
      .catch((err) => dispatch(getProjectsFailure(err)));

  };

};

export const createProjectStart = () => {

  return {
    'type': CREATE_PROJECT_START,
  };

};

export const createProjectSuccess = (project) => {

  return {
    'type': CREATE_PROJECT_SUCCESS,
    'payload': project,
  };

};

export const createProjectFailure = (err) => {

  return {
    'type': CREATE_PROJECT_FAILURE,
    'payload': err.message,
  };

};

export const addProject = (project) => {

  return (dispatch) => {

    dispatch(createProjectStart());
    axios('/project', {
      'method': 'POST',
      'data': {
        project,
      },
    })
      .then((resp) => dispatch(createProjectSuccess(resp.data.result)))
      .catch((err) => dispatch(createProjectFailure(err)));

  };

};

export const updateProjectStart = () => {

  return {
    'type': UPDATE_PROJECT_START,
  };

};

export const updateProjectSuccess = (project) => {

  return {
    'type': UPDATE_PROJECT_SUCCESS,
    'payload': project,
  };

};

export const updateProjectFailure = (err) => {

  return {
    'type': UPDATE_PROJECT_FAILURE,
    'payload': err.message,
  };

};

export const updateProject = (project) => {

  return (dispatch) => {

    dispatch(updateProjectStart());
    axios(`/project/${project.projId}`, {
      'method': 'PUT',
      'data': {
        project,
      },
    })
      .then((resp) => dispatch(updateProjectSuccess(resp.results.data)))
      .catch((err) => dispatch(updateProjectFailure(err)));

  };

};

export const deleteProjectStart = () => {

  return {
    'type': DELETE_PROJECT_START,
  };

};

export const deleteProjectSuccess = (project) => {

  return {
    'type': DELETE_PROJECT_SUCCESS,
    'payload': project,
  };

};

export const deleteProjectFailure = (err) => {

  return {
    'type': DELETE_PROJECT_FAILURE,
    'payload': err.message,
  };

};

export const deleteProject = (projectId) => {

  return (dispatch) => {

    dispatch(deleteProjectStart());
    axios(`/project/${projectId}`, {
      'method': 'DELETE',
      'data': {},
    })
      .then((resp) => dispatch(deleteProjectSuccess(resp.results.data)))
      .catch((err) => dispatch(deleteProjectFailure(err)));

  };

};
