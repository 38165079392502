import React from 'react';

import DocumentTitle from 'react-document-title';

import CircularProgress from '@material-ui/core/CircularProgress';

import LoadingOverlay from 'react-loading-overlay';


const Loading = () => (
  <DocumentTitle title={'HQ | Loading'}>
    <LoadingOverlay
      styles={{
        'overlay': (base) => ({
          ...base,
          'background': 'rgba(255, 255, 255, 0)',
        }),
      }}
      active={true}
      spinner={<CircularProgress size={128} />}
    >
      <div style={{ 'width': '100vw', 'height': '100vh' }} />
    </LoadingOverlay>
  </DocumentTitle>
);

export default Loading;
