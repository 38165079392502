import axios from 'axios';

export const GET_DEPARTMENTS_START = 'GET_DEPARTMENTS_START';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE';

export const getDepartmentsStart = () => {

  return {
    'type': GET_DEPARTMENTS_START,
  };

};

export const getDepartmentsSuccess = (departments) => {

  return {
    'type': GET_DEPARTMENTS_SUCCESS,
    'payload': departments,
  };

};

export const getDepartmentsFailure = (err) => {

  return {
    'type': GET_DEPARTMENTS_FAILURE,
    'payload': err.message,
  };

};

export const getDepartments = () => {

  return (dispatch) => {

    dispatch(getDepartmentsStart());
    axios('departments/general?order=name')
      .then((resp) => dispatch(getDepartmentsSuccess(resp.data.results)))
      .catch((err) => dispatch(getDepartmentsFailure(err)));

  };

};
