import {
  GET_PROJECTS_START,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  CREATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
} from '../actions/project';

const initialState = {
  'projectsLoaded': false,
  'projects': [],
};

export default function reduce(state = initialState, action) {

  switch (action.type) {

    case GET_PROJECTS_START:
      return { ...state, 'projectsLoaded': false };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        'projects': action.payload,
        'projectsLoaded': true,
      };

    case GET_PROJECTS_FAILURE:
      return { ...state, 'projects': [], 'projectsLoaded': true };

    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        'projects': [ ...state.projects, action.payload ],
      };

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        'projects': [ ...state.projects, action.payload ],
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        'projects': state.projects.filter((p) => p.projId !== action.payload.projId),
      };

    default:
      return state;

  }

}
