import React from 'react';
import PropTypes from 'prop-types';

class Bundle extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      'component': null,
    };

    this.loaded = true;

  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {

    this.load(this.props);

  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.load !== this.props.load) {

      this.load(nextProps);

    }

  }

  componentWillUnmount() {

    this.loaded = false;

  }

  load(props) {

    let self = this;

    self.setState({
      'component': null,
    });

    props.load((component) => {

      if (this.loaded) {

        self.setState({
          'component': component.default ? component.default : component,
        });

      }

    });

  }

  render() {

    return this.state.component ? this.props.children(this.state.component) : null;

  }

}

Bundle.propTypes = {
  'load': PropTypes.func.isRequired,
};

export default Bundle;
